<template>
  <div
    class="v-group-barad-dur"
    data-test-id="menu-groups-link-container"
  >
    <common-overflow-elements
      :options="menuStore.GroupsMainPage"
      v-model:selected="selectedGroup"
    >
      <template #itemOverflow="group: Group">
        <arora-nuxt-link
          prefetch
          :href="group.Link"
          :label="sanitize(group.Name)"
          data-test-id="menu-groups-link"
        >
          <span
            :class="
              group.ID === menuStore.CurrentGroupId
                ? 'v-group-barad-dur-title__overflow v-active'
                : 'v-group-barad-dur-title__overflow'
            "
            v-html="sanitize(group.Name)"
          />
        </arora-nuxt-link>
      </template>
      <template #item="{ option }">
        <arora-nuxt-link
          prefetch
          :href="option.Link"
          :label="option.Name"
          data-test-id="menu-groups-link"
        >
          <span
            :class="
              option.ID === menuStore.CurrentGroupId
                ? 'v-group-barad-dur-title v-active'
                : 'v-group-barad-dur-title'
            "
            v-html="sanitize(option.Name)"
          />
        </arora-nuxt-link>
      </template>
    </common-overflow-elements>
  </div>
</template>

<script setup lang="ts">
import type { Group } from '~types/menuStore'

const menuStore = useMenuStore()

const { sanitize } = useI18nSanitized()

const selectedGroup = computed<Group | undefined>({
  get: () => menuStore.GroupsMainPage.find((g) => g.ID === menuStore.CurrentGroupId),
  set: (_value) => {
    //unused because link already sets required id
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-group-barad-dur {
  max-width: 100%;
  font-size: 1.25rem;

  @include mixins.sm {
    font-size: 1.1rem;
  }
}

.v-group-barad-dur-title {
  color: variables.$HeaderColor;
  font-family: variables.$FontFamilyMenuGroups;
  font-size: 1.25rem;
  @include mixins.trans;

  &.v-active {
    color: variables.$PrimaryBackgroundColor;
    font-weight: 600;
  }

  &:hover {
    color: variables.$PrimaryBackgroundColorDarken;

    @include mixins.sm {
      color: variables.$HeaderColor;
    }
  }

  &__overflow {
    color: variables.$BodyTextColor;
    font-size: 1.25rem;

    @include mixins.sm {
      font-size: 1.1rem;
    }

    &.v-active {
      color: variables.$PrimaryBackgroundColor;
      font-weight: 600;
    }
  }

  @include mixins.sm {
    font-size: 1.1rem;
  }
}
</style>
