import { MakeAPIRequest, RequestMethod } from '@arora/common'

export default async function MakeRequest<T>(
  url: string,
  getParametersFromRequest: Map<string, number | string> | null = null,
  method: RequestMethod = RequestMethod.GET,
  data: FormData | string | null = null,
  priority: RequestPriority = 'auto',
  useUrlEncoded = false,
  internallyTyped = true
): Promise<T> {
  const restaurantStore = useRestaurantStore()
  const popupStore = usePopupStore()
  const appConfig = useAppConfig()

  const getParameters = getParametersFromRequest
    ? getParametersFromRequest
    : new Map<string, number | string>()

  getParameters.set('restaurant', appConfig.CurrentRestaurantId)
  if (restaurantStore.SSID) getParameters.set('session', restaurantStore.SSID)

  try {
    return MakeAPIRequest<T>(
      url,
      getParameters,
      !import.meta.dev,
      method,
      data,
      priority,
      useUrlEncoded,
      internallyTyped
    )
  } catch (error) {
    if (error.name === 'TimeoutError') {
      popupStore.showError('errorComponent.connectionTooSlow')
    }
    throw error
  }
}
