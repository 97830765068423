import type { GUID } from '@arora/common'

//TODO: rework this class with @typescript-eslint/no-extraneous-class

export class Guid {
  static Empty = '00000000-0000-0000-0000-000000000000' as GUID

  static IsNullOrEmpty(guid: GUID | null | undefined): boolean {
    return !guid || guid.trim()?.length === 0 || guid === Guid.Empty
  }
}

export const favoriteId: GUID = '00000000-0000-0000-0000-000000000001' as GUID

export enum CallbackNeeded {
  Unset,
  Callback,
  DoNotCallback
}

export enum MinutesSettingType {
  Value = 1,
  Range = 2,
  Default = 3
}

export enum ScaleImageType {
  NoScale = 0,
  ScaleWithDistortion = 1, //масштабировать с искажением пропорций
  ScaleBySmallSide = 2, //масштабировать по меньшей стороне
  ScaleByLargeSide = 3 //масштабировать по большей стороне
}

export enum OrderType {
  Default = -1,
  CourierDelivery = 0,
  NoShipment = 1,
  InHall = 2
}

export enum ErrorCode {
  WrongArgument = 2,
  ThirdPartAPI = 8,
  NotLoggedIn = 8192,
  AlreadyLoggedIn = 16384,
  AlreadyRegistered = 32768
}

export enum AuthType {
  EmbeddedBySms = 0,
  EmbeddedByPassword = 1,
  MCRM = 2,
  IikoCardBySms = 3,
  IikoCardByPassword = 4, //probably unused
  MindboxByPassword = 5
}

export enum AuthOperationsErrorCode {
  Unknown = 1,
  VerificationFailed = 2,
  AlreadyRegistered = 3,
  InvalidMainContact = 4,
  ClientWasNotFound = 5,
  NoPermission = 6,
  PasswordDoesNotMatchPattern = 7,
  PasswordsDoesNotMatch = 8,
  PasswordIsInvalid = 9,
  CaptchaWasNotVerified = 10,
  AlreadyLoggedIn = 11,
  CaptchaNeedRefresh = 12,
  RequiredFieldsWasNotFilledIn = 13,
  CaptchaV3Failed = 14,
  ThirdPartyError = 15
}

export enum MessageType {
  Default = 0,
  Important = 1,
  ImportantNotBlocking = 2,
  DefaultCustom = 3,
  ImportantCustom = 4,
  ImportantNotBlockingCustom = 5
}

export enum RestaurantTypeMode {
  Undefined = 0, //Не задано
  Folder = 1, //Режим папки
  Domain = 2 //Режим домена
}

export enum PayType {
  Undefined = 0,
  Cash = 1,
  BankCard = 2,
  Online = 3,
  Bonus = 4,
  isSelfService = 5,
  GooglePay = 6,
  ApplePay = 7,
  Discount = 8,
  QR = 9
}

/* Дублирует PaymentSortableType для корректной связи PayType и PaymentSortableType*/
export enum PaySortableType {
  AppleGooglePay = 0,
  Cash = 1,
  Online = 2,
  Card = 3,
  QR = 4
}

export enum ConstructorRequiredSettings {
  Unset = 0,
  OneRequired = 1, // Требуется строго один выбранный (x = 1)
  OneNonRequired = 2, // Если будет выбрано, то максимум один (x = 0 или 1)
  OneAndMoreRequired = 3, // Требуется один или больше (x > 1)
  ManyNonRequired = 4 // Можно выбрать много (x >= 0)
}

export enum ProductMeasureType {
  Undefined = 0, //Не задано
  Kilogram, //кг
  Liter, //л
  Piece, //шт
  Portion //порц.
}

//we are choosing to hide pic by this tag
export const ModWithNoPic = 'modifiers_no_photo'

export enum OrderSendErrors {
  None = 0,
  ClientStateAndFormUsed = 1,
  OrderDestinationIsNotValid = 2,
  ClientInBlacklist = 3,
  CantChangeThePhone = 4,
  ForbiddenByOffers = 5,
  CaptchaWasNotVerified = 6,
  CaptchaV3Failed = 7,
  CaptchaV2Failed = 8,
  CartIsEmpty = 9,
  MinSumIsNotReached = 10,
  CartIsInvalid = 11,
  PromoCodeIsInvalid = 16,
  UserDoesNotHaveEnoughPoints = 13,
  ThereIsNoBonusSystem = 14,
  PaymentTypeHasBeenDenied = 15,
  CartItemsAreUnavailable = 12,
  UserDoesNotHaveAnyPoints = 17,
  UnavailableTerminal = 18,
  OrderTimeIsLimited = 19,
  BirthdayPresentsError = 20,
  BonusSystemError = 21,
  Unknown = 22,
  ThirdPartAPI = 23,
  OrderType = 24,
  PaymentTypeIsUndefined = 25
}

export enum OrderStatus {
  Undefined = 0,
  SavedLocally = 4,
  Saved = 10,
  PaymentReadyForProcessing = 14,
  PaymentProcessing = 15,
  Paid = 20,
  ReadyToSend = 30,
  SetToSend = 33,
  Sending = 35,
  Sended = 40,
  PaymentFailed = 70,
  SendFailed = 80,
  Cancelled = 90,
  R_NotConfirmed = 100,
  R_New = 110,
  R_Viewed = 120,
  R_Edited = 122,
  R_Receipt = 125,
  R_Cooking = 130,
  R_Delivering = 140,
  R_Ready = 150,
  R_Delivered = 155,
  R_Close = 160,
  R_Executed = 170,
  Finished = 500,
  DeletedByGuest = 950,
  DeletedAutomatically = 960,
  Double = 970,
  Test = 980,
  Postponed = 989,
  Deleted = 990,
  NotFoundInDB = 991,
  ErrorSavingToBonusSystem = 1000,
  NotEnoughPointsInBonusSystem = 1100
}

export enum PositionType {
  Default = 0, // Основной продукт
  BirthPresent = 1, // Подарок на ДР
  RangedGift = 2, // Подарок из шкалы подарков
  PointPresent = 3, // Подарок за баллы
  MultipleGift = 4, // Подарок на выбор
  Additional = 5, // Дополнительный продукт
  Lost = 6, // Скопированный при повторе заказа продукт
  Discount = 7, // Успей купить
  Recommendation = 8 // Рекомендуемый
}

export enum ProcessingError {
  OK = 0,
  UndefinedError = 1,
  UndefinedOrder = 2, // Отсутствует ID заказа или ID невалиден
  CorruptedOrder = 3,
  PaymentSystemError = 4,
  MobileApplicationDontSupport3DSv1 = 5, // МП не поддерживает проверку 3DS версии 1
  MobileApplicationDontSupport3DSv2 = 6, // МП не поддерживает проверку 3DS версии 2
  PaymentSystemCryptogrammProcessingError = 7, // Ошибка обработки платежа с помощью Apple/Google Pay
  CreateSBPOrderError = 8 // Ошибка при попытке создать оплату по СБП
}

export enum ProductApiType {
  //Некорректная настройка
  Incorrect = 0,

  //Продукт
  Default = 1,

  //Конструктор бизнес ланчей
  Lunch = 2,

  //Конструктор половинок
  Half = 3,

  //Конструктор четвертинок
  Quarter = 4,

  //Комбо
  Combine = 5,

  //Карточка конструктор
  Constructor = 6,

  //Конструктор
  BigConstructor = 7
}

export enum OrderDeliveryType {
  Unset = 0,
  Flat = 1,
  House = 2,
  Org = 3
}

export enum ReceiptSendType {
  Default = 0,
  Email = 1,
  Sms = 2,
  Print = 3
}

export enum LKMode {
  Login = 0,
  LoginBySms = 1,
  RegisterFirstStage = 2,
  RegisterSecondStage = 3,
  RequestNewPassFirstStage = 4,
  RequestNewPassSecondStage = 5,
  LoginBySmsSecondStage = 6,
  ChangePassword = 7
}

export enum PointsType {
  PointsGold = 1,
  PointsCommon = 2
}

export enum OfferCheckResultCode {
  Default = 0, //Корзина изменилась
  ProductLimitedForOneCart = 1, //Ограниченное количество продукта на корзину
  OtherDiscountProdInCart = 2, //В корзине присутствует другой акционный продукт
  OtherMessage = 3, //Выводит сообщение
  OtherRangedGiftProdInCart = 4, //В корзине присутствует другой подарок от суммы
  OtherMultipleGiftProdInCart = 5, //В корзине присутствует другой подарок на выбор
  OtherBirthdayGiftInCart = 6, //В корзине присутствует другой подарок на день рождения
  OffersInfoMessage = 7, //Есть сообщение для пользователя из акций
  NotAvailableForSelfService = 8, //Не доступно для самовывоза
  NotAvailableForDelivery = 9 //Не доступно для доставки
}

export enum ExternalEvent {
  Unknown = 0,
  GACartView = 1,
  GASmallBasketView = 2,
  GABeginCheckout = 3
}

export enum QuizStatus {
  Unknown = 0,
  Available = 1,
  Done = 2
}

export enum ProductDescriptionType {
  Undefined = 0,
  Text = 10,
  Ingredients = 20,
  RemovableIngredients = 30
}

export enum PointsTransactionType {
  Unknown = 0,
  AbortOrder = 101, //Отмена заказа
  AutomaticRefillWallet = 102, //Автоматическое пополнение или списание счёта
  CancelPayFromWallet = 103, //Отмена оплаты заказа со счёта
  CancelRefillWalletFromOrder = 104, //Отмена пополнения баланса из заказа
  CloseOrder = 105, //Закрытие заказа
  CouponActivation = 106, //Активация купона
  DiscountSum = 107, //Применение скидки к заказу
  ExclusionFromProgram = 108, //исключение из программы
  InclusionInProgram = 109, //включение в программу
  MagnetCardChanged = 110, //при изменении карты
  PayFromWallet = 111, //Оплата заказа со счёта
  RefillWallet = 112, //Пополнение счёта через iiko.biz
  RefillWalletFromApi = 113, //Пополнение счёта через API
  RefillWalletFromOrder = 114, //Пополнение счёта из заказа
  RemoveGuestCategory = 115, //Удаление категории гостя
  RemoveGuestCategoryTransaction = 116, //удаление категории у гостя
  ResetAutomaticRefillWallet = 117, //Сгорание периодического пополнения счёта
  ResetRefillWallet = 118, //Сгорание пополнения счёта из iiko.biz
  SetGuestCategory = 119, //Присвоение категории гостя
  SetGuestCategoryTransaction = 120, // назначение категории гостю

  WithdrawWalletFromApi = 121, //Списание счёта через API {НЕТ В ДОКУМЕНТАЦИИ}
  WelcomeBonus = 122 //Начисляется при регистрации {НЕТ В ДОКУМЕНТАЦИИ}
}

export enum AuthVariant {
  Default = 0, //no variant was selected
  Sms = 1,
  Telegram = 2,
  Flashcall = 3
}

export enum FirstAddType {
  Default = 0, // from menu
  Additional = 1, // from extra products
  Recommendation = 2 // from recommendations
}

export enum GuestHardwareType {
  Unknown = 0,
  Android = 1,
  iOS = 2,
  Desktop = 3
}
