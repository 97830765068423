<i18n>
ru:
  searchHint: 'Для корректного поиска введите {num} символа и более'
ua:
  searchHint: 'Для коректного пошуку введіть {num} символи і більше'
us:
  searchHint: 'To perform a correct search, enter {num} or more characters'
</i18n>

<template>
  <div
    class="v-arora-input v-mb-sm v-typeahead"
    :class="{ disabled: disabled }"
    :inert="disabled"
    v-on-click-outside="onClickOutside"
  >
    <input
      :id="uid"
      :aria-label="label.toString()"
      :class="{ 'v-arora-input--has-left-end': showSearchIcon }"
      :data-test-id="dataTestId"
      aria-autocomplete="list"
      maxlength="200"
      placeholder="&nbsp;"
      type="text"
      v-model="userInput"
      @input="onInput"
      @keyup.arrow-down="onArrowDown"
      @keyup.arrow-up="onArrowUp"
      @keyup.enter="onEnter"
    />
    <label
      class="v-arora-input__label"
      :class="{
        'v-arora-input__label--has-left-end': showSearchIcon,
        'v-arora-input__label--has-right-end': showClearIcon
      }"
      :for="uid"
    >
      <span
        class="v-pointer-events-none"
        v-html="label"
      />
      <span
        v-if="required"
        class="v-required"
      />
    </label>
    <span
      v-if="showSearchIcon"
      class="v-arora-input__left-end"
    >
      <icon-general-search />
    </span>
    <span
      v-if="showClearIcon"
      class="v-arora-input__right-end"
    >
      <icon-general-cross
        class="v-pointer v-typeahead__clear"
        @click="clear"
      />
    </span>
    <div
      :id="`${uid}-list`"
      class="v-typeahead-list"
      :data-test-id="dataTestId && `${dataTestId}-list`"
    >
      <div class="v-scrollbar">
        <div
          v-if="!disableSubtitle && suggestions.length === 0"
          class="v-suggestion v-d-flex v-flex-column"
        >
          <span
            v-if="userInput.length < minChars"
            class="v-suggestion__empty--title v-title"
            v-html="translate('typeaheadInput.searchHint', { num: minChars })"
          />
          <span
            v-else-if="!stringIsNullOrWhitespace(zeroResults)"
            class="v-suggestion__empty--title v-title"
            v-html="zeroResults"
          />
          <span
            v-if="zeroResultsSubtitle"
            class="v-suggestion__empty--subtitle"
            v-html="zeroResultsSubtitle"
          />
        </div>
        <div
          v-else
          v-for="(suggestion, index) in suggestions"
          :key="`suggestion-${uid}-${index}`"
          class="v-suggestion"
          :class="{ 'v-suggestion--overlay': hoverIndex === index, disabled: suggestion.disabled }"
          :data-test-id="dataTestId && `${dataTestId}-suggestion`"
          :inert="suggestion.disabled"
          @click="() => suggestionClick(suggestion.onClick)"
          @focusin="focusEvent(index)"
          @focusout="focusEvent(-1)"
          @mouseout="focusEvent(-1)"
          @mouseover="focusEvent(index)"
        >
          <div
            v-if="suggestion.image"
            class="v-suggestion--image v-mr-sm"
          >
            <arora-nuxt-image
              :key="suggestion.ID"
              :alt="suggestion.title"
              :height="35"
              :image="suggestion.image"
              :width="35"
            />
          </div>
          <div class="v-d-flex v-flex-column v-w-100">
            <div
              class="v-w-100"
              :class="[
                $slots.titleEnd
                  ? 'v-align-currency-center v-flex-row-no-wrap v-justify-content-between'
                  : 'v-d-flex v-flex-row-no-wrap v-justify-content-between'
              ]"
            >
              <span
                class="v-suggestion--title v-title"
                v-html="makeHighlightText(suggestion.title)"
              />

              <slot
                v-if="suggestion.item"
                name="titleEnd"
                v-bind="suggestion.item"
              />
            </div>
            <span
              v-if="!stringIsNullOrWhitespace(suggestion.subtitle) && !disableSubtitle"
              class="v-suggestion--subtitle"
              v-html="makeHighlightText(suggestion.subtitle!)"
            />
            <span
              v-if="!stringIsNullOrWhitespace(suggestion.description) && !disableSubtitle"
              class="v-suggestion--description"
              v-html="makeHighlightDescription(suggestion.description!)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import type { TypeaheadOptions } from '~types/common'

import { useCommon, type VInputType, vOnClickOutside } from '@arora/common'
import { gsap } from 'gsap'

const {
  focusOnMount,
  input = null,
  minChars,
  userInputChanged,
  disableSubtitle = false,
  required = false,
  zeroResultsSubtitle = '',
  zeroResults = '',
  onClose = () => {
    return
  }
} = defineProps<
  VInputType & {
    disableSubtitle?: boolean
    input?: string | null
    minChars: number
    onClose?: () => void
    showClearIcon?: boolean
    showSearchIcon?: boolean
    userInputChanged: (value: string) => Promise<TypeaheadOptions<T>[]>
    zeroResults?: string
    zeroResultsSubtitle?: string
  }
>()
const emit = defineEmits(['update:input'])

const { debounce, stringIsNullOrWhitespace } = useCommon()
const { clean, sanitize, translate } = useI18nSanitized()

const uid = useId()

const userInput = ref<string>('')
const suggestions = shallowRef<Awaited<ReturnType<typeof userInputChanged>>>([])

function onArrowDown(): void {
  if (hoverIndex.value < suggestions.value.length - 1) hoverIndex.value++
}
function onArrowUp(): void {
  if (hoverIndex.value > 0) hoverIndex.value--
}

function onEnter(): void {
  if (hoverIndex.value < 0) clear()

  const suggestion = suggestions.value[hoverIndex.value]

  if (suggestion) suggestionClick(suggestion.onClick)
}

const hoverIndex = ref<number>(-1)

function clear(): void {
  userInput.value = ''
  emit('update:input', '')
  suggestions.value = []
  animateSuggestions(false)
  onClose()
}
function onClickOutside(): void {
  userInput.value = input ?? ''
  suggestions.value = []
  animateSuggestions(false)
}

function makeHighlightText(anyText: string): string {
  const userInputAsArray = sanitize(userInput.value).replace(/\s+/g, ' ').trim().split(' ')

  let text = sanitize(anyText)

  for (const userInputVariant of [...new Set(userInputAsArray.sort((a, b) => b.length - a.length))]) {
    text = text.replace(
      new RegExp(userInputVariant.replaceAll('[', '').replaceAll(']', ''), 'ig'),
      (match) => {
        return `<mark class="v-suggestion--highlight">${match}</mark>`
      }
    )
  }

  return text
}

function makeHighlightDescription(anyText: string): string {
  const userInputAsArray = sanitize(userInput.value).replace(/\s+/g, ' ').trim().split(' ')

  const cleanText = sanitize(anyText)

  const textArray = clean(anyText).match(/.{1,50}/g)

  if (!textArray || cleanText.length < 50) return makeHighlightText(anyText)

  let stringStartPiece = ''

  for (let text of textArray) {
    for (const userInputVariant of userInputAsArray) {
      if (text.toLowerCase().includes(userInputVariant.toLowerCase())) {
        text = text.replace(
          new RegExp(userInputVariant.replaceAll('[', '').replaceAll(']', ''), 'ig'),
          (match) => {
            return `<span class="v-suggestion--highlight">${match}</span>`
          }
        )

        return `${stringStartPiece}${text}...`
      }
    }
    stringStartPiece = '...'
  }

  return `${textArray[0]}...`
}

function suggestionClick(callback: () => void): void {
  callback()
  emit('update:input', input)
  onClickOutside()
}

onMounted(() => {
  userInput.value = input ?? ''
})

watch(
  () => input,
  async (newState: string | null, oldState: string | null) => {
    if (newState !== oldState) {
      userInput.value = newState ?? ''
    }
  }
)

function animateSuggestions(isOpen: boolean): void {
  if (!isOpen) hoverIndex.value = -1

  gsap.to(`#${uid}-list`, {
    duration: 0.3,
    ease: 'sine.inOut',
    maxHeight: isOpen ? 250 : 0,
    opacity: isOpen ? 100 : 0
  })
}

function onInput(): void {
  if (userInput.value.length > 0) {
    animateSuggestions(true)
  }

  if (userInput.value.length >= minChars)
    debounce(() =>
      userInputChanged(userInput.value).then((result) => {
        suggestions.value = result
      })
    )
  else suggestions.value = []
}

onMounted(() => {
  if (focusOnMount) document.querySelector<HTMLInputElement>(`#${uid}`)?.focus()
})
function focusEvent(index: number): void {
  hoverIndex.value = index
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-typeahead {
  position: relative;

  input {
    border-radius: variables.$BorderRadiusInput;
    z-index: 10;
  }

  &__clear {
    height: 1.25rem;
    width: 1.25rem;

    &:hover {
      path {
        stroke: variables.$PrimaryBackgroundColorDarken;
      }
    }

    path {
      stroke: variables.$PrimaryBackgroundColor;
    }
  }

  .v-typeahead-list {
    position: absolute;
    z-index: 9;
    top: 42px;

    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    padding: 0.5rem 0;

    background: variables.$FormBackgroundNoOpaque;
    border-radius: variables.$BorderRadiusInput;
    box-shadow: variables.$InputShadow;

    margin-top: 0.5rem;

    //for animation
    max-height: 0;
    opacity: 0;
  }

  .v-suggestion {
    @include mixins.trans;

    width: 100%;

    display: flex;
    flex-direction: row;

    padding: 0.5rem;

    cursor: pointer;

    &.v-currency-wrapper {
      font-weight: 500;
    }

    &--title {
      font-size: variables.$TextSizeMain;
      color: variables.$BodyTextColor;
      max-width: calc(100% - 65px);
      margin-bottom: 4px;
    }

    &--subtitle {
      font-size: variables.$TextSizeLabel;
      font-weight: 700;
      color: variables.$BodyTextColorLight;
      margin-bottom: 1px;
    }

    &--description {
      font-size: 0.85rem;
      color: variables.$BodyTextColorLight;
    }

    &--highlight {
      background: variables.$SearchHighlightBackgroundColor;
      color: variables.$SearchHighlightColor;
    }

    &--image {
      max-width: 40px;
      max-height: 40px;

      .v-broken-image {
        padding: 0;
        max-width: 35px;
        max-height: 35px;
      }
    }

    &--overlay {
      background: variables.$OptionsBackground;
      color: variables.$OptionsColor;
    }

    &__empty {
      &--title {
        font-size: 1.2rem;
        color: variables.$BodyTextColor;
        font-weight: 600;
        padding-left: 1rem;
        padding-bottom: 0.5rem;
      }

      &--subtitle {
        font-size: variables.$TextSizeMain;
        color: variables.$BodyTextColor;
        padding-left: 1rem;
      }
    }
  }
}
</style>
