import clientStateAPI from '~api/clientState'

import type { IMetric, RestaurantSettings } from '~types/restaurantStore'

import { type GUID } from '@arora/common'

const mindboxScriptKey = 'mindbox-script-id'

function getCookie(name: string): string | null {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')
  for (let c of ca) {
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }

  return null
}

export default class MindboxMetric implements IMetric {
  mindbox: any

  constructor(data: RestaurantSettings) {
    //add script to page
    const scriptYandexMap = document.createElement('script')

    scriptYandexMap.setAttribute('id', mindboxScriptKey)
    scriptYandexMap.setAttribute('src', `https://${data.MindboxDomainApi}/scripts/v1/tracker.js`)

    document.head.appendChild(scriptYandexMap)

    scriptYandexMap.addEventListener('load', () => {
      this.mindbox = window.mindbox || {}

      this.mindbox.queue = this.mindbox.queue || []
      this.mindbox('create', {
        endpointId: data.MindboxSiteEndpoint
      })

      const mindboxDeviceUUID = getCookie('mindboxDeviceUUID')

      if (!mindboxDeviceUUID) {
        console.error('Mindbox device UUID is not set!')
      }

      clientStateAPI.setStateSource(mindboxDeviceUUID)
    })
  }

  async sendMetricAddToCart(
    _groupID: GUID,
    _productID: GUID,
    _name: string,
    _priceModified: number,
    _count: number,
    _optionID: GUID
  ): Promise<void> {
    return Promise.resolve(undefined)
  }

  async sendMetricAfterOrder(_orderId: GUID, _totalAmountToPay: number): Promise<void> {
    return Promise.resolve(undefined)
  }

  sendMetricBeforeOrder(): Promise<void> {
    return Promise.resolve(undefined)
  }

  sendMetricCartView(): Promise<void> {
    return Promise.resolve(undefined)
  }

  async sendMetricRemoveFromCart(
    _groupID: GUID,
    _productID: GUID,
    _name: string,
    _count: number
  ): Promise<void> {}

  async SendMetricsProductView(
    _groupID: GUID,
    _productID: GUID,
    _name: string,
    _priceModified: number,
    _count: number,
    _optionID: GUID | undefined
  ): Promise<void> {
    return Promise.resolve(undefined)
  }
}
