<template>
  <div class="v-arora-chip-wrapper v-pl-0">
    <arora-swiper-slider
      enable-scrollbar
      :items="chips"
      :space-between="12"
      max-items="auto"
      pagination-type="none"
    >
      <template #item="chip: ChipsSettings<T>">
        <template v-if="withLinks">
          <arora-nuxt-link
            :active-class="`v-arora-chip v-arora-chip--${theme}__selected`"
            :class-name="`v-arora-chip v-arora-chip--${theme}__default`"
            :external-link="!chip.Link?.startsWith('/')"
            :href="chip.Link ?? ''"
            :label="chip.Name"
            :open-in-new-tab="!chip.Link?.startsWith('/')"
            data-test-id="menu-subgroups-links"
            @click="() => select(chip.ID)"
          />
        </template>
        <template v-else>
          <div
            class="v-arora-chip"
            :class="[
              selected === chip.ID
                ? `v-arora-chip--${theme}__selected`
                : `v-arora-chip--${theme}__default`
            ]"
            @click="() => select(chip.ID)"
            v-html="chip.Name"
          />
        </template>
      </template>
    </arora-swiper-slider>
  </div>
</template>

<script setup lang="ts" generic="T extends GUID | number">
import type { ChipsSettings } from '~types/common'

import type { GUID } from '@arora/common'

const {
  chips,
  selected,
  theme = 'primary'
} = defineProps<{
  selected: T | null | undefined
  chips: ChipsSettings<T>[]
  withLinks?: boolean
  theme?: 'primary' | 'secondary'
}>()

const emit = defineEmits(['update:selected'])

function select(id: T): void {
  emit('update:selected', id)
}

watch(
  () => chips,
  (newValue, oldValue) => {
    if (newValue.length !== oldValue.length && newValue.length > 0) {
      emit('update:selected', newValue[0].ID)
    }
  }
)

onMounted(() => {
  if (!selected && chips.length > 0) emit('update:selected', chips[0].ID)
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-arora-chip-wrapper {
  padding-bottom: 0.9rem;

  .swiper {
    min-height: 3rem;
  }

  .v-swiper .swiper-wrapper {
    padding-bottom: 0;

    .swiper-slide {
      width: fit-content;
    }
  }

  .v-arora-chip {
    @include mixins.trans;

    border: 1px solid variables.$BorderColor;
    border-radius: variables.$BorderRadius;
    padding: 8px 12px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    white-space: nowrap;
    user-select: none;
    -webkit-user-drag: none;

    @include mixins.lg {
      height: 38px;
    }

    @include mixins.sm {
      height: 36px;
    }

    &:hover {
      cursor: pointer;
    }

    &--primary {
      &__default {
        background-color: variables.$BodyBackgroundDarken;
        color: variables.$BodyTextColor;

        &:hover {
          background-color: variables.$BodyBackgroundDarker;
          cursor: pointer;
          box-shadow: variables.$CardShadow;
        }
      }

      &__selected {
        background-color: variables.$PrimaryBackgroundColor;
        color: variables.$PrimaryColor;
        border: 1px solid variables.$PrimaryBackgroundColor;

        &:hover {
          background-color: variables.$PrimaryBackgroundColorDarken;
          color: variables.$PrimaryColor;
          cursor: pointer;
          box-shadow: variables.$CardShadow;
        }
      }
    }

    &--secondary {
      &__default {
        background-color: variables.$BodyBackgroundDarken;
        color: variables.$BodyTextColor;

        &:hover {
          background-color: variables.$BodyBackgroundDarker;
          cursor: pointer;
          box-shadow: variables.$CardShadow;
        }
      }

      &__selected {
        background-color: variables.$SecondaryBackgroundColor;
        color: variables.$SecondaryColor;
        border: 1px solid variables.$SecondaryBackgroundColor;

        &:hover {
          background-color: variables.$SecondaryBackgroundColorDarken;
          color: variables.$SecondaryColor;
          cursor: pointer;
          box-shadow: variables.$CardShadow;
        }
      }
    }
  }
}
</style>
