<template>
  <floating-vue-tooltip
    compute-transform-origin
    eager-mount
    handle-resize
    :delay="delay"
    :disabled="disabled"
    :placement="placement"
    :popper-class="fromPopupLocal ? 'v-popup-popper' : ''"
    :show-group="`popover-group-${uid}`"
    :triggers="isDesktopVersion ? ['hover', 'focus'] : ['click']"
    v-model:shown="isMenuShown"
  >
    <slot name="hoverable">
      <span class="v-popover-icon v-pointer">
        <icon-menu-info />
      </span>
    </slot>

    <template #popper>
      <slot name="content" />
    </template>
  </floating-vue-tooltip>
</template>

<script setup lang="ts">
import type { PopoverPlacement } from '~types/props'

import { type CanBeDisabled, useWindowSize } from '@arora/common'
import { Tooltip as FloatingVueTooltip } from 'floating-vue'

import 'floating-vue/dist/style.css'

const { placement = 'bottom', delay = 0 } = defineProps<
  CanBeDisabled & {
    placement?: PopoverPlacement
    delay?: number
  }
>()

const { isDesktopVersion } = useWindowSize()
const { fromPopup } = useInstance()
const uid = useId()
const fromPopupLocal = fromPopup.value

const isMenuShown = ref<boolean>(false)

onMounted(() => {
  if (import.meta.client) {
    window.addEventListener('scroll', () => (isMenuShown.value = false))
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-popper__popper {
  z-index: 1015;

  &.v-popup-popper {
    z-index: 10000;
  }
}

.v-popover-icon {
  fill: none;
  position: relative;
  cursor: pointer;
}

.v-popper--theme-tooltip {
  .v-popper__inner {
    background: variables.$PopupColor;
    color: variables.$PopupBackground;
    border-radius: 5px;
  }

  .v-popper__arrow-inner {
    border-color: variables.$PopupColor;
  }
}

.v-popper__inner {
  max-width: 320px;
}
</style>
