import type { FirstAddType } from '~api/consts'
import MakeRequest from '~api/request'

import type {
  CartAnswer,
  CartSlot,
  Notification,
  PriceChange,
  PromoInfo,
  StateChangeAnswer
} from '~types/clientStore'

import { type GUID, RequestMethod, useCommon } from '@arora/common'

export default {
  /**
   * Update an item in guest's cart
   * @param {String} itemId - contains a guid
   * @param {Object} count - count to set to given item
   * @param {Object} confirm - user confirmed changes in cart
   */
  async updateItem(
    itemId: GUID,
    count: number,
    confirm: boolean | undefined
  ): Promise<StateChangeAnswer> {
    const getParameters = new Map([
      ['confirmed', (confirm ? 'true' : 'false').toString()],
      ['count', count.toString()],
      ['item', itemId.toString()]
    ])

    return await MakeRequest<StateChangeAnswer>('/cart/updateProduct', getParameters, RequestMethod.POST)
  },

  async activePromo(promo: string): Promise<StateChangeAnswer> {
    const getParameters = new Map([['promo', promo]])

    return await MakeRequest<StateChangeAnswer>('/cart/activePromo', getParameters, RequestMethod.POST)
  },

  async clearPromo(): Promise<StateChangeAnswer> {
    return await MakeRequest<StateChangeAnswer>('/cart/disablePromo', null, RequestMethod.POST)
  },

  async checkPromo(promo: string): Promise<PromoInfo> {
    const getParameters = new Map([['promo', promo]])

    return await MakeRequest<PromoInfo>('/cart/getPromo', getParameters, RequestMethod.POST)
  },

  /**
   * Update an item in guest's cart
   * @param product
   * @param slots
   * @param confirm
   * @param firstAddType
   */
  async addItem(
    product: {
      productId?: GUID
      productID?: GUID
      optionId?: GUID | null | undefined
      optionID?: GUID | null | undefined
    },
    firstAddType: FirstAddType,
    slots: CartSlot[] | undefined,
    confirm: boolean | undefined
  ): Promise<StateChangeAnswer> {
    const { serialize } = useCommon()

    // keep naming consistent (camelCase) to avoid stupid mistakes
    if (product.productId !== null && product.productID === null) {
      product.productID = product.productId
      delete product.productId
    }
    if (product.optionId !== null && product.optionID === null) {
      product.optionID = product.optionId
      delete product.optionId
    }

    const getParameters = new Map([
      ['availableForRestoration', 'true'],
      ['confirmed', confirm ? 'true' : 'false'],
      ['firstAddType', firstAddType.toString()]
    ])

    return await MakeRequest<StateChangeAnswer>(
      '/cart/addProduct',
      getParameters,
      RequestMethod.POST,
      serialize({
        product: product,
        slots: slots
      }),
      'auto',
      true
    )
  },

  /**
   * Delete cart item on server
   * @param count
   * @param {String} itemId - contains a guid
   * @param confirm
   */
  async deleteItem(itemId: GUID, count: number, confirm: boolean): Promise<StateChangeAnswer> {
    const getParameters = new Map([
      ['confirmed', (confirm ? 'true' : 'false').toString()],
      ['count', count.toString()],
      ['item', itemId.toString()]
    ])

    return await MakeRequest<StateChangeAnswer>('/cart/removeProduct', getParameters, RequestMethod.POST)
  },

  async setCallbackState(callbackNeeded: boolean): Promise<StateChangeAnswer> {
    const getParameters = new Map([['callbackState', callbackNeeded.toString()]])

    return await MakeRequest<StateChangeAnswer>(
      '/cart/setCallbackState',
      getParameters,
      RequestMethod.POST
    )
  },

  //сервисный сбор
  async addServiceFee(serviceFee: boolean): Promise<StateChangeAnswer> {
    const getParameters = new Map([['active', serviceFee.toString()]])

    return await MakeRequest<StateChangeAnswer>('/cart/addServiceFee', getParameters, RequestMethod.POST)
  },

  //приоритетная доставка
  async priorityService(priorityService: boolean): Promise<StateChangeAnswer> {
    const getParameters = new Map([['active', priorityService.toString()]])

    return await MakeRequest<StateChangeAnswer>(
      '/cart/priorityService',
      getParameters,
      RequestMethod.POST
    )
  },

  async sendOrderV3(): Promise<CartAnswer> {
    return await MakeRequest<CartAnswer>('/ajax/sendOrderV3', null, RequestMethod.POST)
  },

  async getItemsPriceChanges(): Promise<PriceChange[]> {
    return await MakeRequest<PriceChange[]>('/cart/updatePrices', null, RequestMethod.PATCH)
  },

  async removeProductsFromCart(removeOnlyUnavailableProducts: boolean): Promise<boolean> {
    const getParameters = new Map([['onlyUnavailable', removeOnlyUnavailableProducts.toString()]])

    return await MakeRequest<boolean>('/cart/removeCartContent', getParameters, RequestMethod.GET)
  },
  // восстановление продукта
  async restoreCartItem(itemId: GUID, confirm: boolean | undefined): Promise<StateChangeAnswer> {
    const getParameters = new Map([
      ['confirmed', (confirm ? 'true' : 'false').toString()],
      ['item', itemId]
    ])

    return await MakeRequest('/cart/restoreProduct', getParameters, RequestMethod.POST)
  },
  async getNotifications(maxDate: number, minDate: number): Promise<Notification[]> {
    const getParameters = new Map([
      ['maxdate', maxDate],
      ['mindate', minDate]
    ])

    return await MakeRequest<Notification[]>('/api/json/notifications', getParameters)
  }
}
