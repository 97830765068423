import type { IMetric, RestaurantSettings } from '~types/restaurantStore'

import type { GUID } from '@arora/common'

import GoogleMetric from '~integrations/metric/google'
import MindboxMetric from '~integrations/metric/mindbox'
import YandexMetric from '~integrations/metric/yandex'

export default class GeneralMetric implements IMetric {
  allMetrics: IMetric[]

  constructor(_restId: GUID, data: RestaurantSettings) {
    this.allMetrics = []

    if (data.YandexMetricsSendOrder || data.YandexMetricsSendAddToCart) {
      this.allMetrics.push(new YandexMetric(data))
    }

    if (data.GoogleAnalyticsEnabled) {
      this.allMetrics.push(new GoogleMetric())
    }

    if (data.MindboxEnabled) {
      this.allMetrics.push(new MindboxMetric(data))
    }
  }

  async sendMetricAddToCart(
    GroupID: GUID,
    ProductID: GUID,
    Name: string,
    PriceModified: number,
    Count: number,
    OptionID: GUID
  ): Promise<void> {
    const results: Promise<void>[] = []

    for (const metric of this.allMetrics) {
      results.push(metric.sendMetricAddToCart(ProductID, GroupID, Name, PriceModified, Count, OptionID))
    }

    await Promise.all(results)
  }
  async sendMetricAfterOrder(orderId: GUID, totalAmountToPay: number): Promise<void> {
    const results: Promise<void>[] = []

    for (const metric of this.allMetrics) {
      results.push(metric.sendMetricAfterOrder(orderId, totalAmountToPay))
    }

    await Promise.all(results)
  }

  async sendMetricBeforeOrder(): Promise<void> {
    const results: Promise<void>[] = []

    for (const metric of this.allMetrics) {
      results.push(metric.sendMetricBeforeOrder())
    }

    await Promise.all(results)
  }

  async sendMetricCartView(isSmallCart: boolean): Promise<void> {
    const results: Promise<void>[] = []

    for (const metric of this.allMetrics) {
      results.push(metric.sendMetricCartView(isSmallCart))
    }

    await Promise.all(results)
  }

  async sendMetricRemoveFromCart(
    GroupID: GUID,
    ProductID: GUID,
    Name: string,
    Count: number
  ): Promise<void> {
    const results: Promise<void>[] = []

    for (const metric of this.allMetrics) {
      results.push(metric.sendMetricRemoveFromCart(ProductID, GroupID, Name, Count))
    }

    await Promise.all(results)
  }

  async SendMetricsProductView(
    GroupID: GUID,
    ProductID: GUID,
    Name: string,
    PriceModified: number,
    Count: number,
    OptionID: GUID | undefined
  ): Promise<void> {
    const results: Promise<void>[] = []

    for (const metric of this.allMetrics) {
      results.push(
        metric.SendMetricsProductView(ProductID, GroupID, Name, PriceModified, Count, OptionID)
      )
    }

    await Promise.all(results)
  }
}
