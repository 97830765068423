import type { AuthVariant } from '~api/consts'
import MakeRequest from '~api/request'

import type {
  BirthdayData,
  ChangeMainContactPayload,
  ChangePasswordPayload,
  CurrentRank,
  LoginPayload,
  MapsSavedAddress,
  OrderQuizStatusInfo,
  PartialPayState,
  Points,
  PointsHistory,
  Profile,
  RanksStat,
  ReferralCodeStat,
  RegisterPayload,
  ResetPayload,
  TwoStepsSavedAddress,
  VerifyMainContact
} from '~types/accountStore'
import type { OrderInfo } from '~types/clientStore'

import { type GUID, RequestMethod } from '@arora/common'

export default {
  async getProfile(): Promise<Profile> {
    return await MakeRequest<Profile>('/api/json/v2/profile', null, RequestMethod.GET, null, 'high')
  },

  async saveProfile(fields: Profile, bday: string | null): Promise<Profile> {
    const formData: FormData = new FormData()

    formData.append('recive_email', fields.RecieveNewsEmail.toString())
    formData.append('recive_sms', fields.RecieveNewsSms.toString())

    if (fields.Name) formData.append('name', fields.Name)
    if (fields.Surname) formData.append('surname', fields.Surname)
    if (bday && !fields.BirthDayHasBeenChanged) formData.append('bday', bday)
    if (fields.Email) formData.append('email', fields.Email)
    if (fields.Phone) formData.append('phone', fields.Phone)

    if (fields.MaleIsNull || fields.Male === null) {
      formData.append('male_is_null', '1')
    } else {
      formData.append('male_is_null', '0')
      formData.append('male', fields.Male.toString())
    }

    return await MakeRequest<Profile>('/api/json/v2/profile', null, RequestMethod.PATCH, formData)
  },

  async loadOrderHistory(page: number, count: number): Promise<OrderInfo[]> {
    return await MakeRequest<OrderInfo[]>(
      '/api/json/cabinetHistory',
      new Map([
        ['skip', count * page],
        ['take', count]
      ])
    )
  },

  async login(loginFields: LoginPayload): Promise<unknown> {
    const getParameters: Map<string, string> = new Map()
    const formData: FormData = new FormData()

    if (loginFields.phone) formData.append('phone', loginFields.phone)
    if (loginFields.password) formData.append('password', loginFields.password)
    if (loginFields.country) formData.append('country', loginFields.country)
    if (loginFields.smartcaptcha) formData.append('y-smart-captcha-response', loginFields.smartcaptcha)

    if (loginFields.recaptcha) {
      formData.append('g-recaptcha-response', loginFields.recaptcha)
      if (loginFields.passedV3) {
        formData.append('g-recaptcha-response-v3', loginFields.recaptcha)
        getParameters.set('captchaV3', 'true')
      }
    }

    return await MakeRequest('/api/json/user/logInV3', getParameters, RequestMethod.POST, formData)
  },

  async logout(): Promise<unknown> {
    return await MakeRequest('/ajax/loguot')
  },

  async changeMainContact(restaurantId: GUID, fields: ChangeMainContactPayload): Promise<unknown> {
    const formData: FormData = new FormData()
    if (fields.phone) formData.append('phone', fields.phone)
    if (fields.code) formData.append('code', fields.code)
    if (fields.country) formData.append('country', fields.country)

    formData.append('restaurant', restaurantId)

    return await MakeRequest('/api/json/user/changeMainContact', null, RequestMethod.POST, formData)
  },

  async verifyMainContact(
    restaurantId: GUID,
    authVariant: AuthVariant,
    registerFields: RegisterPayload | ResetPayload
  ): Promise<VerifyMainContact> {
    const getParameters: Map<string, string> = new Map()
    const formData: FormData = new FormData()

    if (registerFields.country) formData.append('country', registerFields.country)
    if (registerFields.phone) formData.append('phone', registerFields.phone)
    formData.append('restaurant', restaurantId)
    formData.append('ignorephonecheck', registerFields.ignorePhoneCheck.toString()) //for iikoCard
    if (registerFields.smartcaptcha)
      formData.append('y-smart-captcha-response', registerFields.smartcaptcha)
    if (registerFields.recaptcha) {
      formData.append('g-recaptcha-response', registerFields.recaptcha)
      if (registerFields.passedV3) {
        formData.append('g-recaptcha-response-v3', registerFields.recaptcha)
        getParameters.set('captchaV3', 'true')
      }
    }
    formData.append('authVariant', authVariant.toString())

    return await MakeRequest<VerifyMainContact>(
      '/api/json/user/verifyMainContactV3',
      getParameters,
      RequestMethod.POST,
      formData
    )
  },

  async register(registerFields: RegisterPayload): Promise<unknown> {
    const getParameters: Map<string, string> = new Map()
    const formData: FormData = new FormData()

    if (registerFields.code) formData.append('code', registerFields.code)
    formData.append('recive_email', registerFields.receiveEmail.toString())
    formData.append('recive_sms', registerFields.receiveSms.toString())
    formData.append('country', registerFields.country)

    if (registerFields.name) formData.append('name', registerFields.name)
    if (registerFields.surname) formData.append('surname', registerFields.surname)
    if (registerFields.bday) formData.append('bday', registerFields.bday)
    if (registerFields.email) formData.append('email', registerFields.email)
    if (registerFields.phone) formData.append('phone', registerFields.phone)

    if (registerFields.smartcaptcha)
      formData.append('y-smart-captcha-response', registerFields.smartcaptcha)
    if (registerFields.recaptcha) {
      formData.append('g-recaptcha-response', registerFields.recaptcha)
      if (registerFields.passedV3) {
        formData.append('g-recaptcha-response-v3', registerFields.recaptcha)
        getParameters.set('captchaV3', 'true')
      }
    }
    formData.append('male_is_null', registerFields.maleIsNull.toString())
    formData.append('male', registerFields.male.toString())

    return await MakeRequest('/api/json/user/register', getParameters, RequestMethod.POST, formData)
  },

  async resetPassword(resetFields: ResetPayload): Promise<unknown> {
    const formData: FormData = new FormData()

    if (resetFields.country) formData.append('country', resetFields.country)
    if (resetFields.code) formData.append('code', resetFields.code)
    if (resetFields.phone) formData.append('phone', resetFields.phone)
    formData.append('ignorephonecheck', 'true') //for iikoCard

    return await MakeRequest('/api/json/user/resetPassword', null, RequestMethod.POST, formData)
  },

  async changePassword(registerFields: ChangePasswordPayload): Promise<unknown> {
    const formData: FormData = new FormData()

    if (registerFields.country) formData.append('country', registerFields.country)
    if (registerFields.newPassword) formData.append('newPassword', registerFields.newPassword)
    if (registerFields.newPasswordRepeat)
      formData.append('newPasswordRepeat', registerFields.newPasswordRepeat)
    if (registerFields.phone) formData.append('phone', registerFields.phone)

    return await MakeRequest('/api/json/user/changePassword', null, RequestMethod.POST, formData)
  },

  async deleteAccount(deleteReason: string): Promise<string> {
    const formData: FormData = new FormData()

    formData.append('deleteReason', deleteReason)

    return await MakeRequest<string>(
      '/api/json/user/deleteAccount',
      new Map([['deleteReason', deleteReason]]),
      RequestMethod.POST,
      formData
    )
  },

  async getPartialPay(): Promise<PartialPayState> {
    return MakeRequest<PartialPayState>('/cart/partialpay/state')
  },

  async partialPayValueChange(value: string | null): Promise<unknown> {
    const formData: FormData = new FormData()
    formData.append('usedPoints', value ?? '0')

    return await MakeRequest('/cart/partialpay/state', null, RequestMethod.PATCH, formData)
  },

  async getBirthdayGifts(): Promise<BirthdayData> {
    return await MakeRequest('/api/json/birthday-presents')
  },

  async getTwoStepsSavedAddresses(): Promise<TwoStepsSavedAddress[] | null> {
    return await MakeRequest<TwoStepsSavedAddress[]>('/api/json/cabinetAddress')
  },

  async addTwoStepsAddress(
    restaurantId: GUID,
    addressFields: TwoStepsSavedAddress
  ): Promise<TwoStepsSavedAddress[]> {
    const formData = new FormData()

    formData.append('AreaName', addressFields.AreaName ?? '')
    formData.append('CloserDepartmentID', addressFields.CloserDepartmentID ?? '')
    formData.append('AddressType', addressFields.AddressType ?? '')
    formData.append('Id', addressFields.Id ?? '')
    formData.append('CloserTerminalID', addressFields.CloserTerminalID ?? '')
    formData.append('Street', addressFields.Street ?? '')
    formData.append('Floor', addressFields.Floor ?? '')
    formData.append('ZipCode', addressFields.ZipCode ?? '')
    formData.append('Building', addressFields.Building ?? '')
    formData.append('House', addressFields.House ?? '')
    formData.append('Doorway', addressFields.Doorway ?? '')
    formData.append('Comment', addressFields.Comment ?? '')
    formData.append('Intercome', addressFields.Intercome ?? '')
    formData.append('Corpus', addressFields.Corpus ?? '')
    formData.append('Apartment', addressFields.Apartment ?? '')
    formData.append('CityName', addressFields.CityName ?? '')
    formData.append('AreaID', addressFields.AreaID ?? '')
    formData.append('CityID', addressFields.CityID ?? '')
    formData.append('OrderDeliveryType', (addressFields.OrderDeliveryType ?? 0).toString())

    return await MakeRequest<TwoStepsSavedAddress[]>(
      '/api/json/cabinetAddress',
      new Map([['restaurant', restaurantId]]),
      RequestMethod.POST,
      formData
    )
  },

  async changeTwoStepsAddress(restaurantId: GUID, addressFields: TwoStepsSavedAddress): Promise<string> {
    const formData = new FormData()

    formData.append('AreaName', addressFields.AreaName ?? '')
    formData.append('CloserDepartmentID', addressFields.CloserDepartmentID ?? '')
    formData.append('AddressType', addressFields.AddressType ?? '')
    formData.append('Id', addressFields.Id ?? '')
    formData.append('CloserTerminalID', addressFields.CloserTerminalID ?? '')
    formData.append('Street', addressFields.Street ?? '')
    formData.append('Floor', addressFields.Floor ?? '')
    formData.append('ZipCode', addressFields.ZipCode ?? '')
    formData.append('Building', addressFields.Building ?? '')
    formData.append('House', addressFields.House ?? '')
    formData.append('Doorway', addressFields.Doorway ?? '')
    formData.append('Comment', addressFields.Comment ?? '')
    formData.append('Intercome', addressFields.Intercome ?? '')
    formData.append('Corpus', addressFields.Corpus ?? '')
    formData.append('Apartment', addressFields.Apartment ?? '')
    formData.append('CityName', addressFields.CityName ?? '')
    formData.append('AreaID', addressFields.AreaID ?? '')
    formData.append('CityID', addressFields.CityID ?? '')
    formData.append('OrderDeliveryType', (addressFields.OrderDeliveryType ?? 0).toString())

    return await MakeRequest<string>(
      '/api/json/cabinetAddress',
      new Map([['restaurant', restaurantId]]),
      RequestMethod.PATCH,
      formData
    )
  },

  async deleteTwoStepsSavedAddresses(addressId: GUID): Promise<unknown> {
    return await MakeRequest(
      '/api/json/cabinetAddress',
      new Map([['id', addressId]]),
      RequestMethod.DELETE
    )
  },

  async addMapsAddress(
    restaurantId: GUID,
    addressFields: MapsSavedAddress
  ): Promise<MapsSavedAddress[]> {
    const formData = new FormData()
    formData.append('restaurant', restaurantId)
    formData.append('Region', addressFields.Region)
    formData.append('City', addressFields.City)
    formData.append('District', addressFields.District)
    formData.append('Street', addressFields.Street)
    formData.append('House', addressFields.House)
    formData.append('Corpus', addressFields.Corpus ?? '')
    formData.append('Building', addressFields.Building ?? '')
    formData.append('Apartment', addressFields.Apartment ?? '')
    formData.append('Doorway', addressFields.Doorway ?? '')
    formData.append('Intercom', addressFields.Intercom ?? '')
    formData.append('Floor', addressFields.Floor ?? '')
    formData.append('Office', addressFields.Office ?? '')
    formData.append('AddressType', addressFields.AddressType ?? '')
    formData.append('Comment', addressFields.Comment ?? '')
    formData.append('ZipCode', addressFields.ZipCode ?? '')
    formData.append('OrderDeliveryType', addressFields.OrderDeliveryType?.toString() ?? '')

    return await MakeRequest<MapsSavedAddress[]>(
      '/api/json/clientAddress',
      null,
      RequestMethod.POST,
      formData
    )
  },

  async changeMapsAddress(restaurantId: GUID, addressFields: MapsSavedAddress): Promise<unknown> {
    const formData = new FormData()
    formData.append('ID', addressFields.ID)
    formData.append('restaurant', restaurantId)
    formData.append('Region', addressFields.Region)
    formData.append('City', addressFields.City)
    formData.append('District', addressFields.District)
    formData.append('Street', addressFields.Street)
    formData.append('House', addressFields.House)
    formData.append('Corpus', addressFields.Corpus ?? '')
    formData.append('Building', addressFields.Building ?? '')
    formData.append('Apartment', addressFields.Apartment ?? '')
    formData.append('Doorway', addressFields.Doorway ?? '')
    formData.append('Intercom', addressFields.Intercom ?? '')
    formData.append('Floor', addressFields.Floor ?? '')
    formData.append('Office', addressFields.Office ?? '')
    formData.append('AddressType', addressFields.AddressType ?? '')
    formData.append('Comment', addressFields.Comment ?? '')
    formData.append('ZipCode', addressFields.ZipCode ?? '')
    formData.append('OrderDeliveryType', addressFields.OrderDeliveryType?.toString() ?? '')

    return await MakeRequest(
      '/api/json/clientAddress',
      new Map([['restaurant', restaurantId]]),
      RequestMethod.PATCH,
      formData
    )
  },

  async getMapsSavedAddresses(): Promise<MapsSavedAddress[] | null> {
    return await MakeRequest('/api/json/clientAddress')
  },

  async deleteMapsSavedAddresses(deleteParameters: { id: GUID; restaurant: GUID }): Promise<unknown> {
    return await MakeRequest(
      '/api/json/clientAddress',
      new Map([['id', deleteParameters.id]]),
      RequestMethod.DELETE,
      JSON.stringify(deleteParameters)
    )
  },

  async addOrRemoveFromFavorites(productId: GUID, isFavorite: boolean): Promise<boolean> {
    return await MakeRequest<boolean>(
      '/api/json/favorites',
      new Map([['ProductID', productId]]),
      isFavorite ? RequestMethod.DELETE : RequestMethod.POST
    )
  },

  async loadReferralCodeInfo(): Promise<ReferralCodeStat> {
    return await MakeRequest<ReferralCodeStat>('/api/json/referral-code')
  },

  async saveReferralCodeInfo(code: string): Promise<ReferralCodeStat> {
    const formData = new FormData()
    formData.append('code', code)

    return await MakeRequest<ReferralCodeStat>(
      '/api/json/referral-code',
      null,
      RequestMethod.POST,
      formData
    )
  },

  async loadPointsHistory(page: number, count: number): Promise<PointsHistory> {
    return await MakeRequest<PointsHistory>(
      '/api/json/pointsHistory',
      new Map([
        ['skip', count * page],
        ['take', count]
      ])
    )
  },

  async loadCurrentRank(): Promise<CurrentRank> {
    return await MakeRequest<CurrentRank>('/api/json/client-rank')
  },

  async loadRanks(): Promise<RanksStat> {
    return await MakeRequest<RanksStat>('/api/json/ranks')
  },

  async loadBalance(): Promise<Points> {
    return await MakeRequest<Points>('/api/json/v2/points')
  },

  async getQuizStatusFromAsker(orderIds: GUID[]): Promise<OrderQuizStatusInfo[]> {
    const formData = new FormData()

    for (const orderId of orderIds) formData.append('orders_ids[]', orderId)

    return await MakeRequest<OrderQuizStatusInfo[]>(
      '/ajax/GetQuizStatusFromAsker',
      null,
      RequestMethod.POST,
      formData
    )
  }
}
